<!--
 * @Author: nice
 * @Date: 2021-11-13 12:31:14
 * @LastEditTime: 2021-12-05 16:37:01
 * @LastEditors: nice
 * @Description: 欠费记录
-->
<template>
  <section class="h100 payment-records">
    <NavBar title="欠费记录" background="blue"/>
    <van-notice-bar v-show="!platformStatus.enable" scrollable :text="this.platformStatus.msg" background="opacity"
                    left-icon="volume-o"/>
    <!-- <div class="date-wrap" @click="show = true">
      <span class="year-month">{{ currentYear }}年</span>
      <van-icon name="arrow-down" color="#C7C7CC" />
    </div> -->
    <van-popup v-model="show" position="bottom" :style="{ height: '45%' }">
      <van-picker
        show-toolbar
        :columns="columns"
        :default-index="columns.length - 1"
        @confirm="dateSure"
        @cancel="show = false"
      />
    </van-popup>
    <LoadingS v-if="loading"/>
    <template v-if="pageListData.records && pageListData.records.length !== 0">
      <ul :class="['list-wrap']">
        <van-list v-model="loading" :finished="noMore" finished-text="没有更多了" @load="handleInfiniteOnLoadDown">
          <van-cell
            is-link
            v-for="(item, index) in pageListData.records"
            :key="index"
            @click="goToDetail(item)">
            <template #title>
              <div>用水月份：{{ $tMonth(item.useWaterTime) }}</div>
              <div>账单日期：{{ $tDate(item.createTime) }}</div>
            </template>
            <template #default>
              <span class="blue">{{ item.amountMoney }}元</span>
            </template>
          </van-cell>
        </van-list>
      </ul>
    </template>
    <template v-else>
      <van-empty v-if="!loading" description="暂无数据"/>
    </template>
    <div class="btn-wrap-x">
      <van-button v-show="platformStatus.enable" :disabled="pageListData.records && pageListData.records.length === 0"
                  type="info" class="self-btn" color="#5196FF" @click="payAtOnce">立即缴费
      </van-button>
    </div>
  </section>
</template>

<script>
import LoadingS from '@/components/loading/Loading.vue';
import moment from 'moment';
import {
  getAccountOrderByCustomerNumberId,
  getPlatformStatus,
} from '@/services/payServer.js';

export default {
  components: {
    LoadingS
  },
  data() {
    const {item, keyword} = this.$route.query;
    const paseItem = JSON.parse(item);
    return {
      meterInfo: paseItem,
      userValue: keyword,
      columns: [],
      show: false,
      currentYear: moment().year(),
      loading: false,
      noMore: false,
      pageListData: {},
      /**
       * accountId、customerNumberId 都填=全部订单查询
       */
      parameters: {
        size: 20,
        current: 1,
        customerNumberId: paseItem?.customerNumberId // 填入用户号id=查询所有缴费订单和充值到表端的订单
      },
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    };
  },
  mounted() {
    this.createDate();
  },
  created() {
    this.hideshare();
    this.getListData();
    this.loadPlatformStatus();
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.call('hideOptionMenu');
    },
    hideshare() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    // 动态生成年份
    createDate() {
      const startDate = moment().subtract(10, 'year').year(); // 前10年
      const endDate = moment().year();
      for (let i = startDate; i <= endDate; i++) {
        this.columns.push(i);
      }
    },
    // 获取分页数据
    getListData() {
      this.loading = true;
      if (this.noMore) return;
      getAccountOrderByCustomerNumberId({...this.parameters}).then(res => {
        if (+res.code === 9200) {
          this.loading = false;
          if (this.parameters.current === 1) {
            this.pageListData = {...res.data};
          } else {
            if (!this.noMore) {
              this.pageListData = {...res.data, records: [...this.pageListData.records, ...res.data.records]};
            }
          }
          this.noMore = +res.data.lzPage.pages === +res.data.lzPage.current;
        }
      })
        .catch(e => {
          this.loading = false;
        });
    },
    handleInfiniteOnLoadDown() {
      this.parameters.current += 1;
      this.noMore = false;
      this.$nextTick(() => {
        this.getListData();
      });
    },
    dateSure(value) {
      this.currentYear = value;
      this.show = false;
    },
    payAtOnce() {
      this.$router.push({
        name: 'arrearsPayAtOnce',
        query: {meterInfo: JSON.stringify(this.meterInfo), keyword: this.userValue}
      });
    },
    goToDetail(item) {
      this.$router.push({name: 'arrearsDetail', query: {item: JSON.stringify(item)}});
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then(res => {
        let platform = this.util.getTranscateRst(res);
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    }
  }
};
</script>

<style lang="less">
.payment-records {
  .list-wrap {
    height: calc(100vh - 46px - 25px - 50px - 10px - 5px);
    padding: 0;
  }

  .date-wrap {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    height: 25px;
    line-height: 35px;
    padding: 0 16px;

    .year-month {
      margin-right: 5px;
    }
  }

  .btn-wrap-x {
    height: 50px;
    padding: 0 13px;
    margin-top: 5px;

    .self-btn {
      height: 40px;
      width: 100%;
    }
  }
}
</style>
